import React from "react";
import * as Immutable from 'immutable';
import PageHeader from "../component/PageHeader";
import authentication from "../services/authentication"
import Button from "@material-ui/core/Button/Button";
import {UserInfo} from "firebase";


type AProps = {
  user: UserInfo,
  userData: any,
  teams: Immutable.List<string>,
  displaySuccessSnack: (message: string) => void,
  displayErrorSnack: (message: string) => void,
}
type AState = {
}
class AccountPage extends React.Component<AProps, AState> {

  logout = () => {
    authentication.signOut();
  };


  resetPassword = () => {
    authentication
      .resetPassword(this.props.user.email)
      .then(() => {
        this.props.displaySuccessSnack("Please check your email for instructions to reset your password");
        this.logout();
      })
      .catch(error => {
        console.log(error);
        this.props.displayErrorSnack("Error resetting your password. Please contact the system admin.");
      });
  };


  render() {
    return(
      <div>
        <PageHeader header={"My Account"}>
          <Button variant="contained" color="primary" onClick={this.resetPassword} style={{marginRight: 10}}>
            Reset password
          </Button>
          <Button variant="contained" color="primary" onClick={this.logout}>
            Logout
          </Button>
        </PageHeader>
      </div>
    );
  }
}

export default AccountPage;