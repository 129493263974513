import React from "react";
import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container/Container";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import authentication from "../services/authentication";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import Box from "@material-ui/core/Box/Box";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";



type LPState = {
  email: string,
  errorMessage: string,
  isLoading: boolean,
}
type LProps = {
  displaySuccessSnack: (message: string) => void,
  displayErrorSnack: (message: string) => void,
}
class ResetPasswordPage extends React.Component<LProps & RouteComponentProps, LPState> {

  constructor(props: LProps & RouteComponentProps) {
    super(props);
    this.state = {
      email: "",
      errorMessage: "",
      isLoading: false,
    }
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({email: event.target.value, errorMessage: ""});
  };

  onSubmit = () => {
    this.setState({isLoading: true});
    authentication.resetPassword(this.state.email)
      .then(() => {
        this.setState({isLoading: false});
        this.props.history.push("/");
        this.props.displaySuccessSnack("Please check your email for instructions to reset your password");
      })
      .catch((error) => {
        console.error(error);
        this.setState({errorMessage: error.message, isLoading: false});
      })
  };

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={10}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{marginTop: 20, marginBottom: 20}}>
            Password Reset
          </Typography>
          <form noValidate onSubmit={(e: any) => {e.preventDefault(); this.onSubmit();}}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={this.onEmailChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{marginTop: 20}}
              disabled={this.state.isLoading}
            >
              Reset
            </Button>
            <div style={{textAlign: "center", marginTop: 30}}>
              <FormLabel error={this.state.errorMessage !== ""}>{this.state.errorMessage}</FormLabel>
            </div>
            <NavLink to={"/"} style={{display: "block", width: "100%", textAlign: "center", marginTop: 20}}>
              Log in
            </NavLink>
            <div style={{textAlign: "center"}}>
              {this.state.isLoading && <CircularProgress />}
            </div>
          </form>
        </Box>
      </Container>
    )
  };

}

export default withRouter(ResetPasswordPage);