import React from 'react';
import * as Immutable from "immutable";
import * as uuid from 'uuid';
import './App.css';
import {Box} from "@material-ui/core";
import {Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter} from "react-router-dom";
import Menu from './component/Menu';
import ShoppingPage from "./page/ShoppingPage";
import CartPage from "./page/CartPage";
import HistoryPage from "./page/HistoryPage";
import InventoryPage from "./page/InventoryPage";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import LoginPage from "./page/LoginPage";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert, {AlertProps, Color} from '@material-ui/lab/Alert/Alert';
import { auth, firestore } from "./services/firebase";
import AccountPage from "./page/AccountPage";
import {UserInfo} from "firebase";
import UsersPage from "./page/UsersPage";
import * as firebase from "firebase/app";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import OrdersPage from "./page/OrdersPage";
import ResetPasswordPage from "./page/ResetPasswordPage";
import SignupPage from "./page/SignupPage";



const teamsData = [
  "All",
  "Alumni",
  "Communications",
  "Events",
  "International Partnerships",
  "Student Recruitment",
  "Visitors",
];


export type Product = {
  id: string,
  name: string,
  qtyAvailable: number,
  price: number,
}


export type Order = {
  id: string,
  user: User,
  createdAt: number,
  costCenter?: string,
  fund?: string,
  items: Array<OrderItem>,
}


export type OrderItem = {
  productId: string,
  productName: string,
  qty: number,
  unitPrice: number,
}


export type CartItem = {
  productId: string,
  qty: number
}


export type User = {
  id: string,
  email: string,
  name: string,
  team: string,
  isEnabled?: boolean,
  isAdmin?: boolean,
}


export type OrderTableItem = {
  key: string,
  orderId: string,
  createdAt: number,
  costCenter?: string,
  fund?: string,
  productName: string,
  userName: string,
  team: string,
  qty: number,
  unitPrice: number,
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const PrivateRoute = (props: RouteProps & { isAuthenticated: boolean, isUserEnabled: boolean }) => {
  if (props.isAuthenticated) {
    return props.isUserEnabled
      ? <Route exact={props.exact} path={props.path}>{props.children}</Route>
      : (
       <p className={"text-center"}>
          Your account is not enabled yet. Please contact a system administrator to enabled it.
       </p>
      )
  }
  return <LoginPage/>;
};


type AState = {
  inventory: Immutable.List<Product>,
  cart: Immutable.List<CartItem>,
  history:  Immutable.List<Order>,
  teams: Immutable.List<string>,
  isSnackOpen: boolean,
  snackMessage: string,
  snackSeverity: Color,
  user?: UserInfo,
  userData: any,
  isAdmin: boolean,
  hasInitialized: boolean,
  isUserEnabled: boolean,
}
class App extends React.Component<{} & RouteComponentProps, AState> {
  private authObserver: firebase.Unsubscribe | undefined;
  private userListener: any | undefined;
  private productsListener: any | undefined;
  private ordersListener: any | undefined;


  constructor(props: {} & RouteComponentProps) {
    super(props);
    this.state = {
      inventory: Immutable.List.of(),
      cart: Immutable.List.of(),
      history: Immutable.List.of(),
      teams: Immutable.List.of(...teamsData),
      isSnackOpen: false,
      snackMessage: "",
      snackSeverity: "success",
      userData: null,
      hasInitialized: false,
      isAdmin: false,
      isUserEnabled: false
    };

  }


  componentDidMount() {
    this.authObserver = auth.onAuthStateChanged(user => {
        // The user is not signed in or doesn’t have a user ID.
        if (!user || !user.uid) {
          this.resetState();
          return;
        }

        // The user is signed in, begin retrieval of external user data.
        this.userListener = firestore.collection("users").doc(user.uid)
          .onSnapshot(snapshot => {

              // The user doesn’t have a data point, equivalent to not signed in.
              if (!snapshot.exists) {
                this.resetState();
                return;
              }

              // The user doesn’t have a data point, equivalent to not signed in.
              const data = snapshot.data();
              if (!data) {
                this.resetState();
                return;
              }

              const isAdmin = data.isAdmin === true;
              const isEnabled = data.isEnabled === true;

              this.setState({
                user: user,
                userData: data,
                isAdmin: isAdmin,
                isUserEnabled: isEnabled,
                hasInitialized: true,
              });

              if (isEnabled) {
                this.productsListener = firestore.collection("products")
                  .onSnapshot(snapshot => {
                    const inventory = snapshot.docs.map(a => {
                      const data = a.data();
                      return { id: data.id, name: data.name, qtyAvailable: data.qtyAvailable, price: data.price };
                    });
                    this.setState({ inventory: Immutable.List.of(...inventory) });
                  });

                this.ordersListener = firestore.collection("orders")
                  .onSnapshot(snapshot => {
                    this.setState({ history: Immutable.List.of(...snapshot.docs.map(a => a.data() as Order)) });
                  });
              }
            },
            error => {
              console.log("Error", error);
              this.resetState();
            }
          );
      },
      error => {
        console.log("Error", error);
        this.resetState();
      }
    );
  }


  componentWillUnmount() {
    this.authObserver && this.authObserver();
  }


  isAuthenticated = (): boolean => {
    return this.state.user !== undefined && this.state.user.email !== undefined && this.state.user.email !== null;
  };


  resetState = () => {
    this.setState({
      inventory: Immutable.List.of(),
      cart: Immutable.List.of(),
      history: Immutable.List.of(),
      user: undefined,
      userData: null,
      isUserEnabled: false,
      isAdmin: false,
      hasInitialized: true
    });

    this.userListener && this.userListener();
    this.ordersListener && this.ordersListener();
    this.productsListener && this.productsListener();
    this.props.history.push("/");
    console.log("Reset state")
  };


  onUpdateCart = (code: string, qty: number) => {
    if (qty <= 0) {
      this.setState({cart: this.state.cart.filter(c => c.productId !== code)});
    } else {
      this.setState({cart: this.state.cart.filter(c => c.productId !== code).push({productId: code, qty})});
    }
  };


  onCheckout = (costCenter: string, fund: string) => {
    const inventory = this.state.inventory;
    const inventoryToUpdate: Array<{productId: string, qty: number}> = [];

    const order: Order = {
      id: uuid.v4(),
      user: {
        id: this.state.user !== undefined ? this.state.user.uid : "",
        email: this.state.user !== undefined && this.state.user.email !== null ? this.state.user.email : "",
        name: this.state.userData.name,
        team: this.state.userData.team
      },
      items: [],
      createdAt: new Date().getTime(),
    };
    if (costCenter && costCenter !== "") {
      order.costCenter = costCenter;
    }
    if (fund && fund !== "") {
      order.fund = fund;
    }

    this.state.cart.forEach(c => {
      const itemIndex = inventory.findIndex(i => i.id === c.productId);
      if (itemIndex >= 0) {
        let item = inventory.get(itemIndex);
        if (item) {
          inventoryToUpdate.push({productId: c.productId, qty: c.qty});
          order.items.push({
            productId: c.productId,
            productName: item.name,
            qty: c.qty,
            unitPrice: item.price
          });
        }
      }
    });

    // clear cart
    this.setState({cart: Immutable.List.of()});

    // update inventory
    inventoryToUpdate.forEach(item =>
      firestore.collection("products").doc(item.productId)
        .update({qtyAvailable: firebase.firestore.FieldValue.increment(-item.qty)})
        .then(() => { })
        .catch((error: any) => console.error("Unable to save inventory", error))
    );

    // update history
    firestore.collection("orders").doc(order.id)
      .set(order)
      .then(() => {
        this.displaySuccessSnack("Checkout completed!");
        setTimeout(() => this.props.history.push("/orders"), 500);

      })
      .catch(error => console.error("Unable to complete checkout", error));
  };



  editInventoryItem = (item: Product) => {
    firestore.collection("products").doc(item.id)
      .update({
        name: item.name,
        qtyAvailable: item.qtyAvailable,
        price: item.price,
        updatedAt: new Date().getTime(),
      })
      .then(() => this.displaySuccessSnack("Item saved"))
      .catch(() => this.displayErrorSnack("Error saving item"));
  };

  addInventoryItem = (name: string, qtyAvailable: number, price: number) => {
    const id = uuid.v4();
    firestore.collection("products").doc(id)
      .set({
        id: id,
        name: name,
        qtyAvailable: qtyAvailable,
        price: price,
        createdAt: new Date().getTime(),
      })
      .then(() => this.displaySuccessSnack("New item added"))
      .catch(() => this.displayErrorSnack("Error creating new item"));
  };

  deleteInventoryItem = (productId: string) => {
    firestore.collection("products").doc(productId)
      .delete()
      .then(() => this.displaySuccessSnack("Item deleted"))
      .catch(() => this.displayErrorSnack("Error deleting item"));
  };

  onCloseCheckoutSnack = () => {
    this.setState({isSnackOpen: false, snackSeverity: "success"});
  };


  displaySuccessSnack = (message: string) => {
    this.setState({isSnackOpen: true, snackMessage: message, snackSeverity: "success"});
  };


  displayErrorSnack = (message: string) => {
    this.setState({isSnackOpen: true, snackMessage: message, snackSeverity: "error"});
  };


  render() {
    const ids = this.state.cart.map(c => c.productId).toSet();
    const selected = this.state.inventory.filter(i => ids.contains(i.id));
    const sortedInventory = this.state.inventory.sortBy(item => item.name);
    const isAuthenticated = this.isAuthenticated();
    const hasInitialized = this.state.hasInitialized;
    const user = this.state.user !== undefined ? this.state.user : {} as UserInfo;
    return (
      <div>
        <CssBaseline/>
        <Menu
          isAdmin={this.state.isAdmin}
          cartSize={this.state.cart.count()}
          userData={this.state.userData}
          isAuthenticated={isAuthenticated}
        />
        <Box m={5}>
          <Switch>
            {!hasInitialized && (
              <Route path={"/"}>
                <div style={{marginTop: 50, textAlign: "center"}}>
                  <CircularProgress />
                </div>
              </Route>
            )}
            <Route exact path="/login">
              <LoginPage/>
            </Route>
            <Route exact path="/reset">
              <ResetPasswordPage displayErrorSnack={this.displayErrorSnack} displaySuccessSnack={this.displaySuccessSnack}/>
            </Route>
            <Route exact path="/signup">
              {isAuthenticated ? <Redirect to={"/"}/> : <SignupPage/>}
            </Route>
            <PrivateRoute isAuthenticated={isAuthenticated} isUserEnabled={this.state.isUserEnabled} exact path="/inventory">
              <InventoryPage
                inventory={sortedInventory}
                onEditItem={this.editInventoryItem}
                onNewItem={this.addInventoryItem}
                onDeleteItem={this.deleteInventoryItem}
              />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated}  isUserEnabled={this.state.isUserEnabled} exact path="/users">
              <UsersPage teams={this.state.teams} displaySuccessSnack={this.displaySuccessSnack} displayErrorSnack={this.displayErrorSnack} />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated} isUserEnabled={true} exact path="/account" >
              <AccountPage user={user} userData={this.state.userData} teams={this.state.teams} displaySuccessSnack={this.displaySuccessSnack} displayErrorSnack={this.displayErrorSnack} />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated} isUserEnabled={this.state.isUserEnabled}exact path="/cart">
              <CartPage inventory={selected} cart={this.state.cart} onUpdate={this.onUpdateCart} onConfirm={this.onCheckout}/>
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated} isUserEnabled={this.state.isUserEnabled} exact path="/orders">
              <OrdersPage history={this.state.history} userId={user.uid}/>
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated} isUserEnabled={this.state.isUserEnabled} exact path="/history">
              <HistoryPage history={this.state.history} />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated} isUserEnabled={this.state.isUserEnabled} exact path="/">
              <ShoppingPage inventory={sortedInventory} cart={this.state.cart} onUpdate={this.onUpdateCart}/>
            </PrivateRoute>
            <Route path="/">
              <Redirect to={"/"}/>
            </Route>
          </Switch>
        </Box>
        <Snackbar open={this.state.isSnackOpen} autoHideDuration={5000} onClose={this.onCloseCheckoutSnack}>
          <Alert onClose={this.onCloseCheckoutSnack} severity={this.state.snackSeverity}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }

}

export default withRouter(App);
