import React from 'react';
import * as Immutable from  "immutable";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {CartItem, Product} from "./../App";
import Box from "@material-ui/core/Box/Box";
import {freeOrAmount} from "../services/money";



type IRProps = {
  inventoryItem: Product,
  cartItem?: CartItem,
  onUpdate: (code: string, qty: number) => void
}
type IRState = {
  value: string
}
class InventoryRow extends React.Component<IRProps, IRState> {


  constructor(props: IRProps) {
    super(props);
    this.state = {
      value: props.cartItem && props.cartItem.qty > 0 ? props.cartItem.qty + "" : ""
    }
  }

  onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      this.setState({value: event.target.value});
    } else {
      const value = Number.parseInt(event.target.value);
      if (!Number.isNaN(value) && value >= 0 && value <= this.props.inventoryItem.qtyAvailable) {
        this.setState({value: value + ""});
      }
    }
  };

  onSave = () => {
    const value = Number.parseInt(this.state.value);
    if (Number.isNaN(value)) {
      this.resetQty();
    } else {
      this.props.onUpdate(this.props.inventoryItem.id, value);
    }
  };

  resetQty = () => {
    this.setState({value: ""});
    this.props.onUpdate(this.props.inventoryItem.id, 0);
  };

  render() {
    const hasError = this.props.cartItem && this.props.cartItem.qty > this.props.inventoryItem.qtyAvailable;
    const inCart = this.props.cartItem && this.props.cartItem.qty > 0;
    const balance = this.props.cartItem && this.props.cartItem.qty > 0
      ? this.props.inventoryItem.qtyAvailable - this.props.cartItem.qty
      : -1;
    const price = freeOrAmount(this.props.inventoryItem.price);
    const total = this.props.inventoryItem.price > 0 && this.props.cartItem && this.props.cartItem.qty > 0
      ? freeOrAmount(this.props.cartItem.qty * this.props.inventoryItem.price)
      : "";
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={this.props.inventoryItem.id}>
        <TableCell align={'left'}>
          {this.props.inventoryItem.name}
        </TableCell>
        <TableCell align={'left'}>
          <Box display="flex" flexDirection="row" alignContent={"center"}>
            <span>
              {this.props.inventoryItem.qtyAvailable}
            </span>
            {balance >= 0 && (
              <span style={{marginLeft: 10, color: "green"}}> > {balance}</span>
            )}
          </Box>
        </TableCell>
        <TableCell align={"left"}>
          <span>
            {price}
          </span>
          {total !== "" && (
            <span style={{marginLeft: 10, color: "green"}}> > {total}</span>
          )}
        </TableCell>
        <TableCell align={'right'}>
          <TextField
            variant={"outlined"}
            size={"small"}
            type="number"
            error={hasError}
            style={{ width: 100 }}
            value={this.state.value}
            onChange={this.onValueChange}
            onBlur={this.onSave}
          />
          <IconButton disabled={!inCart} color="secondary" onClick={ this.resetQty } style={{marginLeft: 10}}>
            <CloseIcon fontSize="small" style={{opacity: inCart ? 1 : 0}}/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}





type ITProps = {
  inventory: Immutable.List<Product>,
  cart: Immutable.List<CartItem>,
  onUpdate: (code: string, qty: number) => void
}
type ITState = {
}
export default class InventoryTable extends React.Component<ITProps, ITState> {
  render() {
    return (
      <div style={{width: '100%'}}>
        <TableContainer style={{maxHeight: 600}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align={'left'} style={{minWidth: 300}}>
                  Item
                </TableCell>
                <TableCell align={'left'} style={{minWidth: 150}}>
                  Available
                </TableCell>
                <TableCell align={'left'} style={{minWidth: 150}}>
                  Price
                </TableCell>
                <TableCell align={'right'} style={{minWidth: 250}}>
                  <span style={{paddingRight: 100}}>
                    Quantity
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.inventory.map((item: Product) =>
                <InventoryRow
                  key={item.id}
                  inventoryItem={item}
                  cartItem={this.props.cart.find(c => c.productId === item.id)}
                  onUpdate={this.props.onUpdate}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}


