import { auth, firestore } from "./firebase";

const authentication = {};



authentication.signIn = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject({message: "Email and password cannot be blank"});
      return;
    }

    auth.signInWithEmailAndPassword(emailAddress, password)
      .then(value => {
        const user = value.user;
        const uid = user.uid;
        if (!user || !uid) {
          reject();
          return;
        }

        const userDoc = firestore.collection("users").doc(uid);
        userDoc.get({ source: "server" })
          .then(value => {
            if (value.exists) {
              resolve(user);
            } else {
              userDoc.set({name: emailAddress.split("@")[0], email: emailAddress, team: "", isAdmin: false, isEnabled: false}, { merge: true })
                .then(() => {
                  resolve(user);
                })
                .catch(reason => {
                  reject(reason);
                });
            }
          })
          .catch(reason => {
            reject(reason);
          });
      })
      .catch(reason => {
        reject(reason);
      });
  });
};



authentication.signOut = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    auth.signOut()
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};




authentication.signUpWithEmailAddressAndPassword = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject("Email or password invalid");
      return;
    }

    if (auth.currentUser) {
      reject("Already logged in");
      return;
    }

    auth.createUserWithEmailAndPassword(emailAddress, password)
      .then(value => {
        const user = value.user;
        if (!user) {
          reject("Something went wrong");
          return;
        }

        const uid = user.uid;
        if (!uid) {
          reject("Something went wrong");
          return;
        }

        const userDoc = firestore.collection("users").doc(uid);
        userDoc.set({name: emailAddress.split("@")[0], email: emailAddress, team: "", isAdmin: false, isEnabled: false}, { merge: true })
          .then(value => {
            resolve(value);
          })
          .catch(reason => {
            reject(reason);
          });
      })
      .catch(reason => {
        reject(reason);
      });
  });
};



authentication.resetPassword = emailAddress => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject();
      return;
    }

    auth.sendPasswordResetEmail(emailAddress)
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};



authentication.changeName = name => {
  return new Promise((resolve, reject) => {
    if (!name) {
      reject();
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    const uid = currentUser.uid;
    if (!uid) {
      reject();
      return;
    }

    firestore.collection("users").doc(uid)
      .update({ name: name })
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};


authentication.changeTeam = team => {
  return new Promise((resolve, reject) => {
    if (!team) {
      reject();
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    const uid = currentUser.uid;
    if (!uid) {
      reject();
      return;
    }

    firestore.collection("users").doc(uid)
      .update({ team: team })
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};


authentication.changeIsEnabled = isEnabled => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    const uid = currentUser.uid;
    if (!uid) {
      reject();
      return;
    }

    firestore.collection("users").doc(uid)
      .update({ isEnabled: isEnabled })
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};



authentication.changeIsAdmin = isAdmin => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      reject();
      return;
    }

    const uid = currentUser.uid;
    if (!uid) {
      reject();
      return;
    }

    firestore.collection("users").doc(uid)
      .update({ isAdmin: isAdmin })
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};





// authentication.getRoles = () => {
//   return new Promise((resolve, reject) => {
//     const currentUser = auth.currentUser;
//
//     if (!currentUser) {
//       reject();
//       return;
//     }
//
//     currentUser.getIdTokenResult()
//       .then(idTokenResult => {
//         resolve(idTokenResult.claims.roles);
//       })
//       .catch(reason => {
//         reject(reason);
//       });
//   });
// };


// authentication.isAdmin = () => {
//   return new Promise((resolve, reject) => {
//     authentication.getRoles()
//       .then(value => {
//         resolve(value && value.includes("admin"));
//       })
//       .catch(reason => {
//         reject(reason);
//       });
//   });
// };




export default authentication;