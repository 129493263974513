import React from "react";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import {RouteComponentProps, withRouter} from "react-router-dom";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Box from "@material-ui/core/Box/Box";
import Badge from "@material-ui/core/Badge/Badge";

export type MProps = {
  cartSize: number,
  userData: any,
  isAuthenticated: boolean,
  isAdmin: boolean
}
const Menu = (props: MProps & RouteComponentProps) => {
  const labelStyle = {fontSize: 10, marginTop: 2};
  const userName = ((props.userData || {}).name || "...").split(" ")[0];
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{flexGrow: 1, cursor: 'pointer'}} onClick={() => props.history.push("/")}>
          Univentory
        </Typography>
        {props.isAuthenticated && (
          <div>
            <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/"))}>
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <HomeOutlinedIcon fontSize={"small"}/>
                <div style={labelStyle}>Home</div>
              </Box>
            </Button>
            { props.isAdmin && (
              <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/inventory"))}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <SettingsOutlinedIcon fontSize={"small"}/>
                  <div style={labelStyle}>Inventory</div>
                </Box>
              </Button>
            )}
            { props.isAdmin && (
              <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/users"))}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <PeopleOutlinedIcon fontSize={"small"}/>
                  <div style={labelStyle}>Users</div>
                </Box>
              </Button>
            )}
            <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/orders"))} >
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <ListAltOutlinedIcon fontSize={"small"}/>
                <div style={labelStyle}>My Orders</div>
              </Box>
            </Button>
            <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/history"))} >
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <AssignmentOutlinedIcon fontSize={"small"}/>
                <div style={labelStyle}>All Orders</div>
              </Box>
            </Button>
            <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/cart"))}>
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Badge badgeContent={props.cartSize} color="secondary" max={9}>
                  <ShoppingCartOutlinedIcon fontSize={"small"}/>
                </Badge>
                <div style={labelStyle}>Cart</div>
              </Box>
            </Button>
            <Button color="inherit" size={"medium"} style={{marginLeft: 10}} onClick={() => props.history.push(("/account"))}>
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <PersonOutlineOutlinedIcon fontSize={"small"}/>
                <div style={labelStyle}>{userName}</div>
              </Box>
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Menu);
