import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";


type PHProps = {
  header: string
  children?: any,
}
const PageHeader = (props: PHProps) => {
  return(
    <Box display={"flex"} flexDirection={"row"} flexGrow={1} justifyContent={"space-between"} my={3}>
      <Typography variant={"h6"}>{props.header}</Typography>
      <div>
        {props.children}
      </div>
    </Box>
  );
};

export default PageHeader;