import React from "react";
import * as Immutable from 'immutable';
import InventoryTable from "../component/InventoryTable";
import {CartItem, Product} from "../App";
import PageHeader from "../component/PageHeader";
import TextField from "@material-ui/core/TextField/TextField";


type IPProps = {
  inventory: Immutable.List<Product>,
  cart: Immutable.List<CartItem>,
  onUpdate: (code: string, qty: number) => void
}
type IPState = {
  searchValue?: string
}
class ShoppingPage extends React.Component<IPProps, IPState> {

  constructor(props: IPProps) {
    super(props);
    this.state = {
      searchValue: ""
    }
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value});
  };

  render() {
    const filteredInventory = this.props.inventory
      .filter(item => item.name.toUpperCase().indexOf((this.state.searchValue || "").toUpperCase()) >= 0);
    return(
      <div>
        <PageHeader header={"Shopping"}>
          <TextField
            label={"Search"}
            variant={"outlined"}
            size={"small"}
            type="text"
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
          />
        </PageHeader>
        <InventoryTable inventory={filteredInventory} cart={this.props.cart} onUpdate={this.props.onUpdate}/>
      </div>
    );
  }
};

export default ShoppingPage;