import React from "react";
import * as Immutable from 'immutable';
import PageHeader from "../component/PageHeader";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {User} from "../App";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {firestore} from "../services/firebase";
import Switch from "@material-ui/core/Switch/Switch";
import Chip from "@material-ui/core/Chip/Chip";
import Box from "@material-ui/core/Box/Box";

type SDProps = {
  user?: User,
  teams: Immutable.List<string>,
  open: boolean;
  onEditUser: (user: User) => void,
  onClose: () => void,
}
type SDState = {
  id: string,
  name: string
  team: string,
  isAdmin?: boolean,
  isEnabled?: boolean,
}
class UserDialog extends React.Component<SDProps, SDState> {

  constructor(props: SDProps) {
    super(props);
    this.state = {
      id: props.user ? props.user.id : "",
      name: props.user ? props.user.name : "",
      team: props.user ? props.user.team : "",
      isEnabled: props.user && props.user.isEnabled === true,
      isAdmin: props.user && props.user.isAdmin === true,
    };
  };


  componentWillReceiveProps(nextProps: SDProps) {
    this.setState({
      id: nextProps.user ? nextProps.user.id : "",
      name: nextProps.user ? nextProps.user.name : "",
      team: nextProps.user ? nextProps.user.team : "",
      isEnabled: nextProps.user && nextProps.user.isEnabled === true,
      isAdmin: nextProps.user && nextProps.user.isAdmin === true,
    });
  }


  onSave = () => {
    if (this.isInputValid()) {
      this.props.onEditUser({
        id: this.props.user !== undefined ? this.props.user.id : "",
        email: this.props.user !== undefined ? this.props.user.email : "",
        name: this.state.name,
        team: this.state.team,
        isEnabled: this.state.isEnabled,
        isAdmin: this.state.isAdmin
      });
      this.props.onClose();
    }
  };

  isInputValid = (): boolean => {
    return this.state.name !== "" && this.state.team !== "";
  };


  onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({name: event.target.value});
  };


  onTeamChange = (event: React.ChangeEvent<any>) => {
    this.setState({team: event.target.value});
  };


  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };


  render() {
    const shrink = this.state.team !== "";
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle id="dialog-title">Edit User</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              label={"Name"}
              variant={"outlined"}
              size={"small"}
              type="text"
              margin={"normal"}
              style={{width: 300}}
              value={this.state.name}
              onChange={this.onNameChange}
            />
          </div>
          <div style={{marginTop: 20}}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                id="demo-simple-select-filled-label"
                shrink={shrink}
                style={{backgroundColor: "#fafafa", paddingLeft: 10, paddingRight: 10}}
              >
                Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={this.state.team}
                onChange={this.onTeamChange}
              >
                <MenuItem value={""}>
                  <em>None</em>
                </MenuItem>
                {this.props.teams.map(team => <MenuItem key={team} value={team}>{team}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <Box display={"flex"} flexDirection={"row"} flexGrow={1} justifyContent={"space-between"} mt={4}>
            <div>
              <span>Enabled</span>
              <Switch color="primary" checked={this.state.isEnabled} onChange={this.handleChange('isEnabled')} value={"isEnabled"}/>
            </div>
            <div>
              <span>Admin</span>
              <Switch color="primary" checked={this.state.isAdmin} onChange={this.handleChange('isAdmin')} value={"isAdmin"}/>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="secondary" onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button autoFocus color="primary" onClick={this.onSave} disabled={!this.isInputValid()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


type AProps = {
  teams: Immutable.List<string>,
  displaySuccessSnack: (message: string) => void,
  displayErrorSnack: (message: string) => void,
}
type AState = {
  isEditUserOpen: boolean,
  user?: User,
  users: Immutable.List<User>,
}
class UsersPage extends React.Component<AProps, AState> {
  private usersListener: any | undefined;

  constructor(props: AProps) {
    super(props);
    this.state = {
      isEditUserOpen: false,
      user: undefined,
      users: Immutable.List.of(),
    };
  }


  componentDidMount() {
    this.usersListener = firestore.collection("users")
      .onSnapshot(snapshot => {
        const users: Array<User> = snapshot.docs.map(user => {
          const userData = user.data();
          return {
            id: user.id,
            email: userData.email,
            name: userData.name,
            team: userData.team,
            isEnabled: userData.isEnabled,
            isAdmin: userData.isAdmin,
          }
        });
        this.setState({users: Immutable.List.of(...users)});
      });
  }


  componentWillUnmount() {
    this.usersListener && this.usersListener();
  }


  onOpenEditUser = (user: User) => {
    this.setState({
      isEditUserOpen: true,
      user: user,
    });
  };


  onEditUser = (user: User) => {
    firestore.collection("users").doc(user.id)
      .update({ name: user.name, team: user.team, isEnabled: user.isEnabled, isAdmin: user.isAdmin })
      .then(() => {
        this.props.displaySuccessSnack("User updated")
      })
      .catch((error) => {
        console.log(error);
        this.props.displayErrorSnack("Error updating user. Please try again");
      });
  };


  onClose = () => {
    this.setState({
      isEditUserOpen: false,
      user: undefined,
    });
  };



  render() {
    return(
      <div>
        <PageHeader header={"Users Admin"} />

        <UserDialog
          teams={this.props.teams}
          open={this.state.isEditUserOpen}
          user={this.state.user}
          onClose={this.onClose}
          onEditUser={this.onEditUser}
        />

        <div style={{width: '100%'}}>
          <TableContainer style={{maxHeight: 600}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align={'left'} style={{minWidth: 200}}>Name</TableCell>
                  <TableCell align={'left'} style={{minWidth: 200}}>Email</TableCell>
                  <TableCell align={'left'} style={{minWidth: 200}}>Team</TableCell>
                  <TableCell align={'left'} style={{minWidth: 100}}>Enabled</TableCell>
                  <TableCell align={'left'} style={{minWidth: 100}}>Admin</TableCell>
                  <TableCell align={'left'} style={{minWidth: 100}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users.sortBy(user => user.name).map(user => (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.team}</TableCell>
                    <TableCell>
                      {user.isEnabled === true
                        ? <Chip size="small" label="Enabled" color={"primary"} style={{ backgroundColor: "#00c853" }} />
                        : <Chip size="small" label="Disabled" color={"secondary"}/>
                      }
                      </TableCell>
                    <TableCell>{user.isAdmin === true && <Chip size="small" label="Admin" color={"primary"} />}</TableCell>
                    <TableCell align={'right'}>
                      <Button color={"primary"} onClick={() => this.onOpenEditUser(user)}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

export default UsersPage;
