import React from "react";
import * as Immutable from 'immutable';
import {Box} from "@material-ui/core";
import InventoryTable from "../component/InventoryTable";
import {CartItem, Product} from "../App";
import Button from "@material-ui/core/Button/Button";
import PageHeader from "../component/PageHeader";
import TextField from "@material-ui/core/TextField/TextField";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";


type IPProps = {
  inventory: Immutable.List<Product>,
  cart: Immutable.List<CartItem>,
  onUpdate: (code: string, qty: number) => void,
  onConfirm: (costCenter: string, fund: string) => void
}
type IPState = {
  costCenter: string,
  fund: string,
  costCenterErrorMessage: string,
  fundErrorMessage: string,
}
export default class CartPage extends React.Component<IPProps, IPState> {

  totalCost: number = 0;

  constructor (props: IPProps) {
    super(props);
    this.state = {
      costCenter: "",
      fund: "",
      costCenterErrorMessage: "",
      fundErrorMessage: "",
    }
  }


  handleChangeCostCenter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length <= 6) {
      this.setState({costCenter: event.target.value.trim(), costCenterErrorMessage: ""});
    }
  };


  handleChangeFund = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length <= 7) {
      this.setState({ fund: event.target.value.trim(), fundErrorMessage: "" });
    }
  };


  isValid = (): boolean => {
    if (this.totalCost > 0) {
      return this.isValidFund() && this.isValidCostCenter();
    }
    return true;
  };


  isValidFund = (): boolean => {
    const fundNumber = Number.parseInt(this.state.fund);
    return this.state.fund.length === 7 && Number.isInteger(fundNumber);
  };


  isValidCostCenter = (): boolean => {
    return this.state.costCenter.length === 6 && this.state.costCenter.startsWith("B");
  };


  validateFund = () => {
    if (!this.isValidFund()) {
      this.setState({ fundErrorMessage: "Invalid fund" });
    }
  };


  validateCostCenter = () => {
    if (!this.isValidCostCenter()) {
      this.setState({ costCenterErrorMessage: "Invalid cost center" });
    }
  };


  render() {
    const { inventory, cart } = this.props;
    this.totalCost = cart.reduce((acc, curr) => acc + (inventory.find(i => i.id === curr.productId) || {price: 0}).price, 0);
    return(
      <div>
        <PageHeader header={"My Cart"}/>
        {this.props.cart.isEmpty() && <div>Your cart is empty</div>}
        {!this.props.cart.isEmpty() && (
          <div>
            <InventoryTable inventory={inventory} cart={cart} onUpdate={this.props.onUpdate}/>
            <Box display="flex" justifyContent="flex-end" mt={3}>

              { this.totalCost > 0 && (
                <div>
                  <TextField
                    label={"Cost Center"}
                    variant={"outlined"}
                    size={"small"}
                    type="text"
                    value={this.state.costCenter}
                    onChange={this.handleChangeCostCenter}
                    onBlur={this.validateCostCenter}
                    style={{ marginRight: 20 }}
                  />
                  <div style={{marginTop: 5}}>
                    <FormLabel error={this.state.costCenterErrorMessage !== ""}>{this.state.costCenterErrorMessage}</FormLabel>
                  </div>
                </div>
              )}

              { this.totalCost > 0 && (
                <div>
                  <TextField
                    label={"Fund"}
                    variant={"outlined"}
                    size={"small"}
                    type="number"
                    value={this.state.fund}
                    onChange={this.handleChangeFund}
                    onBlur={this.validateFund}
                    style={{ marginRight: 20 }}
                  />
                  <div style={{marginTop: 5}}>
                    <FormLabel error={this.state.fundErrorMessage !== ""}>{this.state.fundErrorMessage}</FormLabel>
                  </div>
                </div>
              )}

              <div>
                <Button variant="contained" color="primary" onClick={() => this.props.onConfirm(this.state.costCenter, this.state.fund)} disabled={!this.isValid()}>
                  Confirm
                </Button>
                <div style={{marginTop: 5}}>
                  <FormLabel error={true} style={{ visibility: "hidden" }}>Placeholder</FormLabel>
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
    );
  }
}
