import React from "react";
import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container/Container";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import authentication from "../services/authentication";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import Box from "@material-ui/core/Box/Box";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {NavLink} from "react-router-dom";



type LPState = {
  email: string,
  password: string,
  errorMessage: string,
  isLoading: boolean,
}
class LoginPage extends React.Component<{}, LPState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      isLoading: false,
    }
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({email: event.target.value, errorMessage: ""});
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password: event.target.value, errorMessage: ""});
  };

  onSubmit = () => {
    this.setState({isLoading: true});
    authentication.signIn(this.state.email, this.state.password)
      .then(() => {
        this.setState({isLoading: false});
      })
      .catch((error) => {
        console.error(error);
        this.setState({errorMessage: error.message, isLoading: false});
      })
  };

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={10}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{marginTop: 20, marginBottom: 20}}>
            Log in
          </Typography>
          <form noValidate onSubmit={(e: any) => {e.preventDefault(); this.onSubmit();}}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={this.onEmailChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.onPasswordChange}
            />
            <Button type="submit" fullWidth variant="contained" color="primary" style={{marginTop: 20}} disabled={this.state.isLoading}>
              Log In
            </Button>
            <div style={{textAlign: "center", marginTop: 30}}>
              <FormLabel error={this.state.errorMessage !== ""}>{this.state.errorMessage}</FormLabel>
            </div>
            <NavLink to={"/reset"} style={{display: "block", width: "100%", textAlign: "center", marginTop: 20}}>
              Reset your password
            </NavLink>
            <NavLink to={"/signup"} style={{display: "block", width: "100%", textAlign: "center", marginTop: 20}}>
              Sign up
            </NavLink>
            <div style={{textAlign: "center"}}>
              {this.state.isLoading && <CircularProgress />}
            </div>
          </form>
        </Box>
      </Container>
    )
  };

}

export default LoginPage;