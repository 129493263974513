import React from "react";
import * as Immutable from 'immutable';
import {Product} from "../App";
import Button from "@material-ui/core/Button/Button";
import PageHeader from "../component/PageHeader";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import TextField from "@material-ui/core/TextField/TextField";
import {freeOrAmount} from "../services/money";


type SDProps = {
  item?: Product,
  open: boolean;
  isNew: boolean,
  onEditItem: (item: Product) => void,
  onNewItem: (name: string, qtyAvailable: number, price: number) => void,
  onClose: () => void,
}
type SDState = {
  id: string,
  name: string
  qtyAvailable: number | string,
  price: number | string,
}
class ItemDialog extends React.Component<SDProps, SDState> {

  constructor(props: SDProps) {
    super(props);
    this.state = {
      id: props.item ? props.item.id : "",
      name: props.item ? props.item.name : "",
      qtyAvailable: props.item ? props.item.qtyAvailable : "",
      price: props.item ? props.item.price : "0",
    };
  };


  componentWillReceiveProps(nextProps: SDProps) {
    this.setState({
      id: nextProps.item ? nextProps.item.id : "",
      name: nextProps.item ? nextProps.item.name : "",
      qtyAvailable: nextProps.item ? nextProps.item.qtyAvailable : "",
      price: nextProps.item ? nextProps.item.price : "0",
    });
  }

  onSave = () => {
    if (this.isInputValid()) {
      const qty = Number.parseInt(this.state.qtyAvailable + "");
      const price = Number.parseFloat(this.state.price + "");
      if (this.props.isNew) {
        this.props.onNewItem(this.state.name, qty, price);
      } else {
        this.props.onEditItem({
          id: this.state.id,
          name: this.state.name,
          qtyAvailable: qty,
          price: price
        });
      }
      this.props.onClose();
    }
  };

  isInputValid = (): boolean => {
    const qtyAvailable = Number.parseInt(this.state.qtyAvailable + "");
    const price = Number.parseFloat(this.state.price + "");
    return Number.isInteger(qtyAvailable) && qtyAvailable >= 0
      && !Number.isNaN(price) && price >= 0
      && this.state.name.trim() !== "";
  };


  onQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      this.setState({qtyAvailable: ""});
    } else {
      const value = Number.parseInt(event.target.value);
      if (!Number.isNaN(value) && value >= 0) {
        this.setState({qtyAvailable: value});
      }
    }
  };

  onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      this.setState({price: ""});
    } else {
      const value = Number.parseFloat(event.target.value);
      if (!Number.isNaN(value) && value >= 0) {
        this.setState({price: value});
      }
    }
  };

  onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({name: event.target.value});
  };


  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle id="dialog-title">{this.props.isNew ? "New" : "Edit"} Item</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              label={"Item Name"}
              variant={"outlined"}
              size={"small"}
              type="text"
              margin={"normal"}
              style={{width: 300}}
              value={this.state.name}
              onChange={this.onNameChange}
            />
          </div>
          <div>
            <TextField
              label={"Quantity Available"}
              variant={"outlined"}
              size={"small"}
              type="number"
              style={{width: 300}}
              margin={"normal"}
              value={this.state.qtyAvailable}
              onChange={this.onQtyChange}
            />
          </div>
          <div>
            <TextField
              label={"Price"}
              variant={"outlined"}
              size={"small"}
              type="number"
              style={{width: 300}}
              margin={"normal"}
              value={this.state.price}
              onChange={this.onPriceChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="secondary" onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button autoFocus color="primary" onClick={this.onSave} disabled={!this.isInputValid()}>
            {this.props.isNew ? "Add" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}




export interface DDProps {
  product?: Product,
  open: boolean;
  onCancel: () => void;
  onConfirm: (productId: string) => void;
}

function DeleteDialog(props: DDProps) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
    >
      <DialogTitle id="confirmation-dialog-title">Delete this product?</DialogTitle>
      <DialogContent>
        {props.product ? props.product.name : ""}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.onConfirm(props.product ? props.product.id : "")} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}


type IPProps = {
  inventory: Immutable.List<Product>,
  onEditItem: (item: Product) => void,
  onNewItem: (name: string, qtyAvailable: number, price: number) => void,
  onDeleteItem: (productId: string) => void,
}
type IPState = {
  isDialogOpen: boolean,
  isDeleteDialogOpen: boolean,
  item?: Product,
  isNewItem: boolean,
  searchValue: string,
}
class InventoryPage extends React.Component<IPProps, IPState> {

  constructor(props: IPProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
      isDeleteDialogOpen: false,
      item: undefined,
      isNewItem: true,
      searchValue: "",
    }
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value});
  };

  onOpenEditItem = (item: Product) => {
    this.setState({
      isDialogOpen: true,
      isNewItem: false,
      item: item,
    });
  };

  onOpenNewItem = () => {
    this.setState({
      isDialogOpen: true,
      isNewItem: true,
      item: undefined,
    });
  };

  onEditItem = (item: Product) => {
    this.props.onEditItem(item);
  };

  onNewItem = (name: string, qtyAvailable: number, price: number) => {
    this.props.onNewItem(name, qtyAvailable, price);
  };

  onClose = () => {
    this.setState({
      isDialogOpen: false,
      isDeleteDialogOpen: false,
      item: undefined,
    });
  };


  onOpenDeleteDialog = (item: Product) => {
    this.setState({
      isDeleteDialogOpen: true,
      item: item,
    });
  };

  onDeleteItem = (productId: string) => {
    this.setState({ isDeleteDialogOpen: false });
    this.props.onDeleteItem(productId);
  };



  render() {
    const filteredInventory = this.props.inventory
      .filter(item => item.name.toUpperCase().indexOf((this.state.searchValue || "").toUpperCase()) >= 0);
    return(
      <div>
        <PageHeader header={"Inventory Admin"}>
          <TextField
            label={"Search"}
            variant={"outlined"}
            size={"small"}
            style={{ marginRight: 20 }}
            type="text"
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
          />
          <Button color={"primary"} variant={"contained"} onClick={this.onOpenNewItem} >
            New
          </Button>
        </PageHeader>

        <ItemDialog
          open={this.state.isDialogOpen}
          item={this.state.item}
          onClose={this.onClose}
          isNew={this.state.isNewItem}
          onEditItem={this.onEditItem}
          onNewItem={this.onNewItem}
        />

        <DeleteDialog
          product={this.state.item}
          open={this.state.isDeleteDialogOpen}
          onCancel={this.onClose}
          onConfirm={this.onDeleteItem}
        />

        {this.props.inventory.isEmpty() && <div>Your inventory is empty</div>}
        {!this.props.inventory.isEmpty() && (
          <div style={{width: '100%'}}>
            <TableContainer style={{maxHeight: 600}}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align={'left'} style={{minWidth: 300}}>
                      Item
                    </TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>
                      Available
                    </TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>
                      Price
                    </TableCell>
                    <TableCell align={'right'} style={{minWidth: 200}}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInventory.map(item => (
                    <TableRow key={item.id}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell>
                        {item.qtyAvailable}
                      </TableCell>
                      <TableCell>
                        {freeOrAmount(item.price)}
                      </TableCell>
                      <TableCell align={'right'}>
                        <Button color={"primary"} onClick={() => this.onOpenEditItem(item)}>
                          Edit
                        </Button>
                        <Button color={"secondary"} onClick={() => this.onOpenDeleteDialog(item)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}

export default InventoryPage;