import React from "react";
import * as Immutable from 'immutable';
import {Order, OrderTableItem} from "../App";
import PageHeader from "../component/PageHeader";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TextField from "@material-ui/core/TextField/TextField";
import moment from "moment";
import {freeOrAmount} from "../services/money";

type IPProps = {
  history: Immutable.List<Order>,
}
type IPState = {
  searchValue?: string,
}
class HistoryPage extends React.Component<IPProps, IPState> {

  constructor(props: IPProps) {
    super(props);
    this.state = {
      searchValue: ""
    }
  }


  handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  };


  renderOrderGroup = (values: Array<OrderTableItem>) => {
    const w = [];
    w.push((
      <TableRow key={values[0].key} style={{ verticalAlign: "top" }}>
        <TableCell rowSpan={values.length}>{moment(values[0].createdAt).format("DD/MM/YYYY HH:mm")}</TableCell>
        <TableCell rowSpan={values.length}>{values[0].userName}</TableCell>
        <TableCell rowSpan={values.length}>{values[0].team}</TableCell>
        <TableCell rowSpan={values.length}>{values[0].costCenter}</TableCell>
        <TableCell rowSpan={values.length}>{values[0].fund}</TableCell>
        <TableCell>{values[0].qty}</TableCell>
        <TableCell>{freeOrAmount(values[0].qty * values[0].unitPrice)}</TableCell>
        <TableCell>{values[0].productName}</TableCell>
      </TableRow>
    ));
    for (let i = 1; i < values.length; i++) {
      w.push((
        <TableRow key={values[i].key}>
          <TableCell>{values[i].qty}</TableCell>
          <TableCell>{freeOrAmount(values[i].qty * values[i].unitPrice)}</TableCell>
          <TableCell>{values[i].productName}</TableCell>
        </TableRow>
      ));
    }

    return w;
  };

  render() {
    let ordersMap: Immutable.Map<String, Array<OrderTableItem>> = Immutable.Map.of();
    this.props.history.flatMap(order => order.items.map(item => {
      return {
        key: order.id + item.productId,
        orderId: order.id,
        createdAt: order.createdAt,
        userName: order.user.name,
        team: order.user.team,
        costCenter: order.costCenter,
        fund: order.fund,
        productName: item.productName,
        qty: item.qty,
        unitPrice: item.unitPrice,
      }
    }))
    .filter(item =>
      item.userName.toUpperCase().indexOf((this.state.searchValue || "").toUpperCase()) >= 0
      || item.productName.toUpperCase().indexOf((this.state.searchValue || "").toUpperCase()) >= 0
      || item.team.toUpperCase().indexOf((this.state.searchValue || "").toUpperCase()) >= 0
      || item.key.toUpperCase().indexOf((this.state.searchValue || "").toUpperCase()) >= 0
    )
    .forEach(item => {
      const value = (ordersMap.get(item.orderId) || []).concat(item);
      ordersMap = ordersMap.set(item.orderId, value);
    });

    return(
      <div>
        <PageHeader header={"All Orders"}>
          <TextField
            label={"Search"}
            variant={"outlined"}
            size={"small"}
            type="text"
            value={this.state.searchValue}
            onChange={this.handleSearchValueChange}
          />
        </PageHeader>
        {this.props.history.isEmpty() && <div>Your history is empty</div>}
        {!this.props.history.isEmpty() && (
          <div style={{width: '100%'}}>
            <TableContainer style={{maxHeight: 600}}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align={'left'} style={{minWidth: 100}}>Date</TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>Name</TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>Team</TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>Cost Center</TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>Fund</TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>Quantity</TableCell>
                    <TableCell align={'left'} style={{minWidth: 100}}>Total</TableCell>
                    <TableCell align={'left'} style={{minWidth: 300}}>Item</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersMap.valueSeq()
                    .sortBy((value:Array<OrderTableItem>) => value[0].createdAt)
                    .map((value:Array<OrderTableItem>) => this.renderOrderGroup(value))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
};

export default HistoryPage;