import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";


const firebaseConfig = {
  apiKey: "AIzaSyB7rGp42SF-i8XdVavBviLwVOrBEAev-Yk",
  authDomain: "univentory-4f1bd.firebaseapp.com",
  databaseURL: "https://univentory-4f1bd.firebaseio.com",
  projectId: "univentory-4f1bd",
  storageBucket: "univentory-4f1bd.appspot.com",
  messagingSenderId: "884946022228",
  appId: "1:884946022228:web:14a8e6965dac3d9dd1a2c1"
};


firebase.initializeApp(firebaseConfig);

// firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// });

export default firebase;
// export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
// export const performance = firebase.performance();